<template>
  <v-dialog
    v-model="dialogContrato"
    @click:outside="$emit('update:dialogContrato', false)"
    @keydown.esc="$emit('update:dialogContrato', false)"
    scrollable
  >
    <v-card>
      <v-card-title
        >{{ $tc("global.contrato") }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogContrato', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <ContratoTexto :key="key" :contrato="contrato" />
          </v-col>
          <v-col class="pl-6">
            <v-row dense style="position: fixed; width: 40%">
              <v-col cols="12" class="d-flex">
                <v-select
                  v-model="contrato.cliente_tipo_doc"
                  :items="['CI', 'RUC']"
                  :label="
                    $tc('global.cliente') +
                    ' ' +
                    $tc('global.tipo') +
                    ' ' +
                    $tc('global.documento')
                  "
                  dense
                  class="mr-2"
                >
                </v-select>
                <v-text-field
                  v-model="contrato.cliente_documento"
                  :label="$tc('global.cliente') + ' ' + $tc('global.documento')"
                  dense
                ></v-text-field>
              </v-col>
              <!-- Estado civil -->
              <v-col cols="12">
                <v-autocomplete
                  v-model="contrato.cliente_estado_civil"
                  :label="
                    $tc('global.cliente') + ' ' + $tc('global.estado_civil')
                  "
                  :items="estados_civis"
                  dense
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="contrato.cliente_nacionalidade"
                  :label="
                    $tc('global.cliente') + ' ' + $tc('global.nacionalidade')
                  "
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="contrato.cliente_endereco"
                  :label="$tc('global.cliente') + ' ' + $tc('global.endereco')"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="contrato.produto_marca"
                  :label="$tc('global.produto') + ' ' + $tc('global.marca')"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="contrato.numero_garantia"
                  :label="$tc('global.numero') + ' ' + $tc('global.garantia')"
                  dense
                ></v-text-field>
              </v-col>
              <!-- Cidade (Endereço Judicial) -->
              <v-col cols="12 mb-4 mt-n2">
                <v-text-field
                  v-model="contrato.cidade"
                  :label="$tc('global.endereco') + ' ' + $tc('global.judicial')"
                  dense
                  hide-details
                  :placeholder="
                    $tc('global.endereco') + ' ' + $tc('global.judicial')
                  "
                  full-width
                  class="remove-underline input-font-size"
                ></v-text-field>
              </v-col>
              <!-- Imóvel -->
              <v-col cols="12">
                <v-text-field
                  v-model="contrato.imovel"
                  label="Imovel"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n6 mb-n4">
                <v-checkbox
                  :label="$tc('global.fiador')"
                  v-model="enableFiador"
                />
              </v-col>
              <!-- Estado civil fiador -->
              <v-col v-if="enableFiador" cols="12">
                <v-combobox
                  v-model="contrato.fiador_estado_civil"
                  :label="
                    $tc('global.fiador') + ' ' + $tc('global.estado_civil')
                  "
                  :items="estados_civis"
                  dense
                  required
                ></v-combobox>
              </v-col>
              <v-col v-if="enableFiador" cols="12">
                <v-text-field
                  v-model="contrato.fiador_nacionalidade"
                  :label="
                    $tc('global.fiador') + ' ' + $tc('global.nacionalidade')
                  "
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="enableFiador" cols="12">
                <v-text-field
                  v-model="contrato.fiador_documento"
                  :label="$tc('global.fiador') + ' ' + $tc('global.documento')"
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="enableFiador" cols="12">
                <v-text-field
                  v-model="contrato.fiador_endereco"
                  :label="$tc('global.fiador') + ' ' + $tc('global.endereco')"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="primary"
          @click="gerarContrato"
          :disabled="disableContrato"
        >
          {{ $tc("global.gerar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format, parseISO, parse } from "date-fns";
import esLocale from "date-fns/locale/es";
import { getCliente } from "@/api/clientes/clientes.js";
import { fetchEnderecosClientes } from "@/api/clientes/clientes_enderecos.js";
import { fetchVendasItems } from "@/api/vendas/vendas_items.js";
import writtenNumber from "written-number";
import { fetchClienteReferencias } from "@/api/clientes/clientes_refencias.js";
import { searchCidades } from "@/api/cidades/cidades.js";

export default {
  name: "DialogContrato",

  props: {
    dialogContrato: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
    },
    venda_items: {
      type: Array,
    },
    vendaInProcess: {
      type: Boolean,
      default: false,
    },
    entrega_inicial: {
      type: Number,
      default: null,
    },
    data_entrega_inicial: {
      type: String,
      default: null,
    },
  },

  components: {
    ContratoTexto: () => import("./ContratoTexto.vue"),
  },

  data() {
    return {
      loading: false,
      contrato: {
        numero_venda: this.venda.numero,
        dia_emissao: null,
        mes_emissao: format(parseISO(this.venda.data), "MMMM", {
          locale: esLocale,
        }),
        ano_emissao: null,
        cliente_genero: "El Señor",
        cliente_nome: this.venda.cliente_nome,
        cliente_nacionalidade: "",
        cliente_estado_civil: "",
        cliente_tipo_doc: "RUC",
        cliente_documento: this.venda.cliente_documento,
        cliente_endereco: null,
        numero_garantia: "",
        produto_marca: "",
        produto_kit_id: "",
        venda_items: "",
        venda_total: this.venda.total,
        venda_moeda: this.venda.moeda_sigla,
        parcelamento_texto: "",
        cidade: "",
        imovel: "",
        possui_fiador: false,
        fiador_genero: "El Señor",
        fiador_nome: "",
        fiador_nacionalidade: "",
        fiador_estado_civil: "",
        fiador_tipo_doc: "RUC",
        fiador_documento: "",
        fiador_endereco: "",
      },
      cliente: {},
      cliente_enderecos: [],
      endereco_principal: null,
      // venda_items: [],
      validForm: true,
      selectedCidade: null,
      searchCidade: "",
      buscandoCidades: false,
      cidades: [],
      enableFiador: false,
      key: 0,
      estados_civis: [
        this.$tc("global.solteiro").toUpperCase(),
        this.$tc("global.casado").toUpperCase(),
        this.$tc("global.divorciado").toUpperCase(),
        this.$tc("global.viuvo").toUpperCase(),
      ],
    };
  },

  computed: {
    disableContrato() {
      return (
        !this.contrato.cliente_endereco ||
        !this.contrato.produto_marca ||
        !this.contrato.cidade ||
        !this.contrato.imovel ||
        !this.contrato.cliente_estado_civil ||
        !this.contrato.cliente_nacionalidade ||
        (this.enableFiador &&
          (!this.contrato.fiador_estado_civil ||
            !this.contrato.fiador_documento ||
            !this.contrato.fiador_nacionalidade ||
            !this.contrato.fiador_endereco))
      );
    },
  },

  methods: {
    fetchClienteEnderecos() {
      return fetchEnderecosClientes(this.venda.cliente_id).then((response) => {
        this.cliente_enderecos = response;
        this.endereco_principal = this.cliente_enderecos.find((endereco) => {
          return (endereco.end_principal = true);
        });
        let endereco_completo = "";
        if (this.endereco_principal) {
          endereco_completo +=
            this.endereco_principal.rua +
            ", " +
            this.endereco_principal.bairro +
            ", " +
            this.endereco_principal.numero +
            ", " +
            this.endereco_principal.cidade_nome +
            ", " +
            this.endereco_principal.pais;

          this.contrato.cidade = this.endereco_principal.cidade_nome;
        }
        this.contrato.cliente_endereco = endereco_completo;
      });
    },

    async getCliente() {
      await getCliente(this.venda.cliente_id).then((response) => {
        this.cliente = response;

        if (this.cliente.genero && this.cliente.genero == "FEMININO") {
          this.contrato.cliente_genero = "la Señora";
        }
        if (this.cliente.nacionalidade) {
          this.contrato.cliente_nacionalidade = this.cliente.nacionalidade;
        }
        if (this.cliente.estado_civil) {
          this.contrato.cliente_estado_civil = this.cliente.estado_civil;
        }
        if (!this.cliente.doc_ruc && this.cliente.doc_ci) {
          this.contrato.cliente_tipo_doc = "CI";
          this.contrato.cliente_documento = this.venda.cliente_documento;
        }
        if (this.cliente.localizacao) {
          this.contrato.imovel = this.cliente.localizacao;
        }
      });
    },
    async getClienteReferencia() {
      await fetchClienteReferencias(this.venda.cliente_id).then((response) => {
        this.cliente_referencia = response[0];
        if (
          this.cliente_referencia.genero &&
          this.cliente_referencia.genero == "FEMININO"
        ) {
          this.contrato.fiador_genero = "la Señora";
        }
        if (this.cliente_referencia.nome_conjugue) {
          this.contrato.fiador_nome = this.cliente_referencia.nome_conjugue;
        }
        if (this.cliente_referencia.nacionalidade) {
          this.contrato.fiador_nacionalidade =
            this.cliente_referencia.nacionalidade;
        }
        if (this.cliente_referencia.estado_civil) {
          this.contrato.fiador_estado_civil =
            this.cliente_referencia.estado_civil;
        }
        if (
          !this.cliente_referencia.doc_ruc &&
          this.cliente_referencia.doc_ci
        ) {
          this.contrato.fiador_tipo_doc = "CI";
          this.contrato.fiador_documento = this.cliente_referencia.doc_ci;
        } else {
          this.contrato.fiador_tipo_doc = "RUC";
          this.contrato.fiador_documento = this.cliente_referencia.doc_ruc
            ? this.cliente_referencia.doc_ruc
            : "";
        }
        if (this.cliente_referencia.end_conjugue) {
          this.contrato.fiador_endereco = this.cliente_referencia.end_conjugue;
        }
      });
    },

    async getVendaItems() {
      if (!this.vendaInProcess) {
        const response = await fetchVendasItems(this.venda.id);
        this.venda_items = response;
      }
      // let kit = this.venda_items.find((produto) => {});
      this.venda_items.forEach((produto, index) => {
        if (produto.kit) {
          this.contrato.venda_items +=
            produto.qtde + " " + produto.descricao + " SIENDO: ";
        } else {
          this.contrato.venda_items +=
            produto.qtde + " " + produto.descricao + ", ";
        }
      });
      this.contrato.venda_items = this.contrato.venda_items.slice(0, -2);

      const kit = this.venda_items.find((item) => item.kit);
      if (kit) {
        this.contrato.produto_kit_id = kit.produto_id;
      }
    },

    textoParcelas() {
      let texto = "";
      let group_parcelas = [];
      let moeda = "";
      if (this.contrato.venda_moeda == "G$") {
        moeda = "GUARANIES";
      }
      if (this.contrato.venda_moeda == "U$") {
        moeda = "DOLARES";
      }
      if (this.contrato.venda_moeda == "R$") {
        moeda = "REALES";
      }
      // Array quebra o array de parcelas e agrupa as parcelas do mesmo valor em outros arrays
      const groupByValor = this.venda.parcelas.reduce((group, parcela) => {
        const { valor } = parcela;
        group[valor] = group[valor] ?? [];
        group[valor].push(parcela);
        return group;
      }, {});

      // Pego o valor desses arrays para montar o array de parcelas agrupado
      for (let valor_parcela in groupByValor) {
        let parcela_agrupada = {};
        parcela_agrupada.valor = valor_parcela;
        parcela_agrupada.qtde_parcelas = groupByValor[valor_parcela].length;
        // pego a maior data de cada um dos arrays para colocar como dada ta ultima parcela desse grupo de parcelas
        const maxDate = new Date(
          Math.max(
            ...groupByValor[valor_parcela].map((parcela) => {
              return new Date(parcela.data_vencimento);
            })
          )
        );
        // pego a menor data de cada um dos arrays para colocar como dada ta ultima parcela desse grupo de parcelas
        const minDate = new Date(
          Math.min(
            ...groupByValor[valor_parcela].map((parcela) => {
              return new Date(parcela.data_vencimento);
            })
          )
        );
        parcela_agrupada.primera_parcela = minDate;
        parcela_agrupada.ultima_parcela = maxDate;
        group_parcelas.push(parcela_agrupada);
      }

      // reordeno as parcelas para ficar da primeira para a ultima em seus respectivos grupos
      group_parcelas.sort(
        (objA, objB) =>
          Number(objA.primera_parcela) - Number(objB.primera_parcela)
      );

      // pego ultimo index do array de parcelas ordenadas
      let ultimo_index = group_parcelas.length - 1;

      // Construcao do texto escrito para o contrato
      group_parcelas.forEach((parcela, index) => {
        if (index === 0) {
          let dia_parcela = format(new Date(parcela.primera_parcela), "dd");
          let mes_parcela = format(new Date(parcela.primera_parcela), "MMMM", {
            locale: esLocale,
          });
          let ano_parcela = format(new Date(parcela.primera_parcela), "yyyy");

          // texto apenas da primeira parcela
          texto = `FIRMA (${parcela.qtde_parcelas}) ${writtenNumber(
            parcela.qtde_parcelas,
            { lang: "es" }
          )} PAGARES DE ${moeda} ${writtenNumber(parcela.valor, {
            lang: "es",
          })} (${this.contrato.venda_moeda} ${
            this.contrato.venda_moeda == "G$"
              ? this.$options.filters.guarani(parcela.valor)
              : this.$options.filters.currency(parcela.valor)
          })  CADA UNO, SIENDO EL PRIMER VENCIMIENTO EN DIA ${writtenNumber(
            dia_parcela,
            {
              lang: "es",
            }
          )} ${mes_parcela} ${writtenNumber(ano_parcela, {
            lang: "es",
          })} Y ASI SUCESIVAMENTE CADA MES HASTA SU TOTAL CANCELACION`;
        } else {
          texto += `, MAS (${parcela.qtde_parcelas}) ${writtenNumber(
            parcela.qtde_parcelas,
            { lang: "es" }
          )} PAGARES CADA UNO POR LA SUMA DE ${moeda} ${writtenNumber(
            parcela.valor,
            {
              lang: "es",
            }
          )} (${this.contrato.venda_moeda} ${
            this.contrato.venda_moeda == "G$"
              ? this.$options.filters.guarani(parcela.valor)
              : this.$options.filters.currency(parcela.valor)
          }) CON VENCIMIENTO EN FECHA ${format(
            parcela.primera_parcela,
            "dd-MM-yyyy"
          )} A ${format(parcela.ultima_parcela, "dd-MM-yyyy")}`;
          if (index == ultimo_index) {
            texto += ".";
          }
        }
      });
      this.contrato.parcelamento_texto = texto.toUpperCase();
    },

    gerarContrato() {
      this.$emit("update:contrato", this.contrato);
      this.$emit("update:dialogContrato", false);
      this.$emit("close");
    },

    searchCidades(filtro) {
      return searchCidades(filtro).then((response) => {
        this.cidades = response;
      });
    },

    async getCidades() {
      if (this.searchCidade) {
        if (this.searchCidade.length > 2 && event.key != "Backspace") {
          this.buscandoCidades = true;
          await this.searchCidades(`?search=${this.searchCidade}`);
          this.buscandoCidades = false;
        }
      }
    },

    setEntregaInicial() {
      this.contrato.entrega_inicial = this.venda.entrega_inicial;
      this.contrato.data_entrega_inicial = this.venda.data_entrega_inicial;
      this.setData();
      this.key++;
    },

    setData() {
      this.contrato.dia_emissao = format(parseISO(this.venda.data), "dd");
      this.contrato.ano_emissao = format(parseISO(this.venda.data), "yyyy");
    },
  },

  watch: {
    selectedCidade() {
      if (this.selectedCidade) {
        this.contrato.cidade = this.selectedCidade.nome;
      }
    },
    async enableFiador() {
      if (this.enableFiador) {
        await this.getClienteReferencia();
        this.contrato.possui_fiador = true;
      } else {
        this.contrato.possui_fiador = false;
        this.contrato.fiador_nome = null;
        this.contrato.fiador_estado_civil = null;
        this.contrato.fiador_documento = null;
        this.contrato.fiador_nacionalidade = null;
        this.contrato.fiador_endereco = null;
      }
    },
  },

  async mounted() {
    await this.fetchClienteEnderecos();
    await this.getCliente();
    await this.getVendaItems();
    this.setData();
    this.textoParcelas();
    this.setEntregaInicial();
  },
};
</script>

<style></style>
